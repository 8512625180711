@import url(https://fonts.googleapis.com/css2?family=Zen+Old+Mincho:wght@400;700;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Zen+Old+Mincho:wght@400;700;900&display=swap);
body {
    margin: 0;
    font-family: 'ZenOldMincho';
    overflow-x: hidden;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* .App {
  text-align: center;
} */

body {
    font-family: 'ZenOldMincho';
}

.react-typewriter-text {
    text-align: center!important;
}

.btn-default {
    color: #2d3239;
    background-color: transparent;
    border-color: #2d3239
}

.btn-default.focus,
.btn-default:focus {
    color: #d2cdc6;
    background-color: #2d3239;
    border-color: #000
}

.btn-default:hover {
    color: #d2cdc6;
    background-color: #2d3239;
    border-color: #121417
}

.btn-default.active,
.btn-default:active,
.open>.btn-default.dropdown-toggle {
    color: #2d3239;
    background-color: transparent;
    border-color: #121417
}

.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open>.btn-default.dropdown-toggle.focus,
.open>.btn-default.dropdown-toggle:focus,
.open>.btn-default.dropdown-toggle:hover {
    color: #2d3239;
    background-color: transparent;
    border-color: #000
}

.text-right {
    text-align: right
}

.btn-default.active,
.btn-default:active,
.open>.btn-default.dropdown-toggle {
    background-image: none
}

.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
    background-color: transparent;
    border-color: #2d3239
}

.btn-default .badge {
    color: transparent;
    background-color: #2d3239
}

.loc {
    width: 100%;
    height: 500px;
    background-image: url('https://global-uploads.webflow.com/5e998d8310343e62a9e92ada/5ed9051d1de9e65e69134293_hero-rocket-tower-dark.jpg');
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.main-content {
    background: #fff
}

.container-fluid--small {
    max-width: 999p;
}

.hero-nav-pulled {
    background: #fff;
    padding: 15px 0
}

@media only screen and (min-width:768px) {
    .hero-nav-pulled {
        padding: 30px;
        margin-top: -100px
    }
}

@media only screen and (min-width:1024px) {
    .flex-md {
        display: flex
    }
}

.flex-center-y {
    justify-content: space-between
}

@media (min-width:768px) and (max-width:1023px) {
    .hidden-sm {
        display: none !important
    }
}

@media (max-width:767px) {
    .hidden-xs {
        display: none !important
    }
}

.flex-center-x-y,
.flex-center-y {
    align-items: center
}

.media {
    width: 3500px;
    height: 450px;
    background-image: url('https://ak.picdn.net/shutterstock/videos/23063065/thumb/4.jpg');
    background-repeat: repeat;
    /* background-position: center;
    position: absolute; */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.margin-top-xxl {
    margin-top: 144px
}

@media only screen and (max-width: 600px) {
    .margin-top-xxl {
        margin-top: 45px
    }
    .loc {
        width: 100%;
        height: 100%;
    }
    body {
        overflow-x: hidden;
    }
}

.padding-top-xxl {
    padding-top: 60px
}

.margin-top-l {
    margin-top: 30px
}

.btn-block {
    display: block;
    width: 100%
}

@media only screen and (max-width:767px) {
    .btn-xs-block {
        display: block;
        width: 100%;
        padding-left: 0;
        padding-right: 0
    }
}

@media only screen and (max-width:767px) {
    .btn-block+.btn-block,
    .btn-xs-block+.btn-xs-block {
        margin-top: 15px
    }
}

input[type=button].btn-block,
input[type=reset].btn-block,
input[type=submit].btn-block {
    width: 100%
}

@media only screen and (min-width:768px) {
    .btn+.btn {
        margin-left: 15px
    }
}

.back {
    width: 100%;
    height: 900px;
    background-position: center;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dif {
    background: linear-gradient(102.31deg, rgba(178, 177, 177, 0.15) 0.11%, rgba(229, 229, 229, 0.25) 72.63%);
}


/* .box_center{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.card {
    box-shadow: 0.3em 0.3em 1em rgba(0, 0, 0, 0.3);
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        -webkit-animation: App-logo-spin infinite 20s linear;
                animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@keyframes App-logo-spin {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

.title-white {
    color: #fff;
    border-color: hsla(0, 0%, 100%, .5)
}

.title-white:before {
    border-color: #fff
}

.title-underlined {
    border-bottom: 1px solid #fff
}

.margin-top-xl {
    margin-top: 30px
}

.btn-ghost {
    color: #fff;
    background-color: transparent;
    border-color: #fff
}

.btn-ghost.focus,
.btn-ghost:focus {
    color: #000;
    background-color: #fff;
    border-color: #bfbfbf
}

.btn-ghost:hover {
    color: #000;
    background-color: #fff;
    border-color: #e0e0e0
}

.btn-ghost.active,
.btn-ghost:active,
.open>.btn-ghost.dropdown-toggle {
    color: #fff;
    background-color: transparent;
    border-color: #e0e0e0
}

.btn-ghost.active.focus,
.btn-ghost.active:focus,
.btn-ghost.active:hover,
.btn-ghost:active.focus,
.btn-ghost:active:focus,
.btn-ghost:active:hover,
.open>.btn-ghost.dropdown-toggle.focus,
.open>.btn-ghost.dropdown-toggle:focus,
.open>.btn-ghost.dropdown-toggle:hover {
    color: #fff;
    background-color: transparent;
    border-color: #bfbfbf
}

.btn-ghost.active,
.btn-ghost:active,
.open>.btn-ghost.dropdown-toggle {
    background-image: none
}

.btn-ghost.disabled.focus,
.btn-ghost.disabled:focus,
.btn-ghost.disabled:hover,
.btn-ghost[disabled].focus,
.btn-ghost[disabled]:focus,
.btn-ghost[disabled]:hover,
fieldset[disabled] .btn-ghost.focus,
fieldset[disabled] .btn-ghost:focus,
fieldset[disabled] .btn-ghost:hover {
    background-color: transparent;
    border-color: #fff
}

.btn-ghost .badge {
    color: transparent;
    background-color: #fff
}

.title-with-stripe {
    position: relative;
    padding-bottom: 15px;
    margin-bottom: 33.33333px
}

@media only screen and (min-width:768px) {
    .title-with-stripe {
        margin-bottom: 50px
    }
}

.title-with-stripe.text-white:before {
    border-color: #fff
}

.title-with-stripe.text-white:after {
    border-color: #2d3239
}

.title-with-stripe.gray-stripe:after {
    border-color: #bfbfbf
}

.title-with-stripe:before {
    border-left: 1px solid #2d3239;
    position: absolute;
    content: "";
    left: 50%;
    top: 80%;
    height: 50px
}

@media only screen and (min-width:768px) {
    .title-with-stripe:before {
        height: 100px;
        top: 110%
    }
}

.title-with-stripe:after {
    border-left: 1px solid #fff;
    position: absolute;
    content: "";
    height: 35px;
    left: 50%;
    z-index: 40;
    top: calc(80% + 41px)
}

@media only screen and (min-width:768px) {
    .title-with-stripe:after {
        height: 54px;
        top: calc(110% + 46px)
    }
}

.Sidebar {
    height: 100%;
    border-radius: 20px;
    width: 270px;
    background-color: #3E7DC7;
    position: fixed;
    padding-top: 20px;
}

.SidebarList {
    height: auto;
    padding: 0;
    width: 100%;
}

.title-black {
    color: #2d3239
}

.title-black,
.title-black:before {
    border-color: #2d3239
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: NeuzeitGro-Bol, sans-serif;
    margin: 0
}

.SidebarList .row {
    width: 100%;
    height: 60px;
    list-style-type: none;
    margin: 0%;
    display: flex;
    flex-direction: row;
    color: #fff;
    justify-content: center;
    align-items: center;
}

.row #icon {
    flex: 30% 1;
    display: grid;
    place-items: center;
}

.row #title {
    flex: 70% 1;
}

.SidebarList .row:hover {
    cursor: pointer;
    font-weight: 600;
}

.SidebarList #active {
    font-weight: 600;
}

.side {
    padding: 20px;
}

@media only screen and (max-width: 600px) {
    #promob {
        display: block;
    }
    .row #title {
        display: none;
    }
    .Sidebar {
        height: 85%;
        border-radius: 20px;
        width: 50px;
        background-color: #3E7DC7;
        position: fixed;
    }
    body {
        overflow-x: hidden;
    }
    #allpro {
        display: none;
    }
}

@media only screen and (min-width: 992px) {
    #promob {
        display: none;
    }
}
.navbar {
    background-color: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 20;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80px;
    max-width: 1800px;
}

.nav-logo {
    /* color: #f5b921; */
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;
    padding-bottom: 10px;
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
}

.nav-links {
    color: #606060;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
}

.fa-code {
    margin-left: 1rem;
}

.nav-item {
    line-height: 40px;
    margin-right: 1rem;
}




.nav-icon {
    display: none;
}

@media screen and (max-width: 960px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1pxsolid #fff;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
    }
    .nav-menu.active {
        background: #fff;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }
    .nav-item .active {
        /* color: #ffdd40; */
        border: none;
    }
    .nav-links {
        padding: 1.5rem;
        width: 100%;
        display: table;
    }
    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        -webkit-transform: translate(-100%, 60%);
                transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #000;
    }
}
